.banner {
    display: grid;
    grid-template-columns: 120px auto;
    align-items: center;
}

.content {

    align-self: center;

}

.content p {
    font-weight: normal;
    font-size: 28px;
    margin: 0px;
}

.content h2 {
    padding: 0px;
    margin: 0px;
    font-size: 36px;

}