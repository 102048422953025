.header{
    background-color: #000000;
    border-left: 1px solid #eef0f2;
    border-bottom: 1px solid #eef0f2;
    height: 72px;
    padding: 10px 15px;
    z-index: 999;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    position: relative;
    }