.search{
    align-content: center;
    justify-self: end;
    max-width:800px;
    width: 100%;
    display: grid;
    border-radius: 30px;
    height:100%;
    padding:0px 20px;
}
.search_bar{
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-size: 1.2rem;
    border-radius: 30px;
    padding: 0px 20px;
}
.search_bar input{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 1.2rem;
    border-radius: 30px;
    padding: 0px 20px;
}